var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

$(document).ready(function() {
    // Mobile Nav
	$('nav#mobnav').mmenu({
        onClick: {
            close: true
        },
        offCanvas: {
            position: 'bottom',
            zposition: 'front'
         }
    }, {
        extensions: [
            'offCanvas'
        ]
    });

    // Navigation shrink
    $(window).on('scroll', function(e) {
        var distanceY = $(window).scrollTop() || $(document).scrollTop();
        var screenWidth = $(window).width();
        var shrinkOn = 200;
        var navigation = $('.navbar-intercheese');

        if (distanceY > shrinkOn && screenWidth > 991) {
            navigation.addClass('shrink');
        } else {
            if (navigation.hasClass('shrink')) {
                navigation.removeClass('shrink');
            }
        }
    });

    // Show and hide search field
    $('.search-button').on('click', function(e) {
        $('.site-search').toggleClass('show');
        e.preventDefault();
    });

    // Show and hide language changer
    $(document).on('click', '#changeLanguage', function(e) {
        console.log('KLICK');
        $('.language-changer').toggleClass('show');
        e.preventDefault();
    });

	// Show a certain panel
	var panel = getUrlParameter('panel');
    if (panel) {
        $('#collapse-' + panel).collapse('show');
    } else {
        $('.collapse').first().collapse('show');
    }

});
